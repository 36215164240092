<script setup>
import { computed } from "vue";

const $device = useDevice();

const backgroundImage = computed(() =>
  $device.isDesktop
    ? "url(/img/homepage/bg-hero-desktop.webp)"
    : $device.isMobile
    ? "url(/img/homepage/bg-hero-mobile.webp)"
    : "url(/img/homepage/bg-hero-tablet.webp)"
);
</script>

<template>
  <section
    class="hero h-screen"
    :style="`background-image: ${backgroundImage}`"
  >
    <div class="container px-5 py-16 lg:py-20 md:px-7 lg:px-14 h-full">
      <div class="flex items-end h-full xl:w-8/12">
        <div class="md:max-w-lg lg:max-w-none">
          <h1 class="text-white" :class="$device.isMobile ? 'text-3xl': 'text-6xl'">
            <SplitAnimate
              :duration="0.4"
              text="We Tailor Expert Solutions Allowing Your Business to Thrive"
            />
          </h1>
          <div             
            :class="`${
              $device.isTablet ? 'subheader' : 'description'
            } text-white60 mt-4`" 
          >
          We are a boutique nearshore web development agency.  Our face-to-face, hands-on, personalized approach ensures that your obstacles become solutions.
          </div>
          <div
            :class="`${
              $device.isTablet ? 'subheader' : 'description'
            } text-white60 mt-4`"
          >
          Talk to us to get a customized software solution that addresses your specific business needs.
          </div>
          <div class="mt-10">
            <div class="mt-4">
              <BookFreeCall />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
